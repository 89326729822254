header {
    width: 100%;
    z-index:997;

    #main-header {
        font-weight: $weight-medium;
        border-bottom: $header-menu-border-bottom-width solid $header-menu-border-bottom;

        @include flexbox;
        display: flex;
        width: 100%;
        min-height: 56px;
        background: $header-background;
        padding:0 12px 0 37px;
        @media only screen and (max-width:1199px) {
            padding-left: 13px;
        }
        @media only screen and (max-width:1024px) {
            min-height: 45px;
            padding-left: 0px;
        }
        #logo {
            display: flex;
            align-items: center;
            img {
                max-height: 50px;

                &.logo-mobile {
                    display: none;
                }
            }
        }
        nav {
            &#main-menu, &#user-profile {
                > ul {
                    background: $header-menu-background;
                    @include flexbox;
                    @include justify-content(flex-end);
                    @include align-items(stretch);
                    @include flex-wrap(wrap);

                    > li {
                        text-transform: uppercase;
                        position: relative;
                        background: $header-menu-item-background;

                        &:hover, &:focus {
                            background: $header-menu-item-background-hover;
                            > a {
                                color: $header-menu-item-hover;
                                &.user-profile-icon-container {
                                    color: $header-menu-user-profile-hover;
                                }
                            }
                        }

                        &:not(.submenu-container) {
                            > a {
                                cursor: pointer;
                            }
                            &:active {
                                background: $header-menu-item-background-active;
                                > a {
                                    color: $header-menu-item-active;
                                }
                            }
                        }

                        &:disabled {
                            background: $header-menu-item-background-disabled;
                            > a {
                                color: $header-menu-item-disabled;
                            }
                        }

                        @media only screen and (max-width: 1024px) {
                            display: none;
                        }

                        > a {
                            color: $header-menu-item;
                            padding: 0px 25px;
                            line-height: 56px;
                            cursor: default;

                            &.user-profile-icon-container {
                                color: $header-menu-user-profile;
                            }

                            @media only screen and (max-width: 1024px) {
                                line-height: 45px;
                            }
                        }

                        .subnav {
                            @media only screen and (min-width: 1025px) {
                                display: none;
                                position: absolute;
                                top: calc(53px + #{$header-menu-border-bottom-width});
                                @include box-shadow();
                                background: $header-submenu-background;
                                border-top: 3px solid $header-submenu-border-top;
                                z-index: 999;
                            }

                            &.align-right {
                                right: 0;
                                left: auto;
                            }

                            > li {
                                background: $header-submenu-item-background;
                                a {
                                    color: $header-submenu-item;
                                }

                                &:not(.user-profile-intro) {
                                    &:hover, &:focus {
                                        background: $header-submenu-item-background-hover;
                                        a {
                                            color: $header-submenu-item-hover;
                                        }
                                    }

                                    &:active {
                                        background: $header-submenu-item-background-active;
                                        a {
                                            color: $header-submenu-item-active;
                                        }
                                    }

                                    &:disabled {
                                        background: $header-submenu-item-background-disabled;
                                        a {
                                            color: $header-submenu-item-disabled;
                                        }
                                    }
                                }

                                @media only screen and (min-width: 1025px) {
                                    border-right: 1px solid $header-submenu-border;
                                    border-left: 1px solid $header-submenu-border;
                                    border-bottom: 1px solid $header-submenu-item-border-bottom;

                                    &:last-child {
                                        border-bottom: 1px solid $header-submenu-border;
                                    }
                                }

                                padding-top: 6px;
                                padding-bottom: 6px;

                                &:last-child {
                                    padding-bottom: 6px;
                                }

                                @media only screen and (max-width: 1025px) {
                                    padding: 0;
                                }

                                margin: 0;
                                text-transform: none;

                                a {
                                    @include ellipsis-text();
                                    display: block;
                                    @include height-lineheightsimilarities(30px);
                                    @include padding-similarities(15px);
                                }
                            }
                        }
                    }
                }
            }
            &#main-menu {
                @media only screen and (min-width: 1025px) {
                    flex-grow: 1;
                }
            }
            &#user-profile {
                > ul {
                    .user-profile-icon-container {
                        @include flexbox;
                        @include align-items(center);
                        height: 56px;
                        i {
                            font-family: 'FontAwesome';
                            font-size: 2.3rem;

                            &.logged-in {
                                &:after {
                                    content: " ";
                                    width: 12px;
                                    height: 12px;
                                    -webkit-border-radius: 12px;
                                    -moz-border-radius: 12px;
                                    border-radius: 12px;
                                    background: #7ed321;
                                    position: absolute;
                                    right: 26px;
                                }
                            }
                        }
                    }

                    .user-profile-intro {
                        .user-profile-intro-title {
                            color: $header-submenu-item;
                            text-align: center;
                            font-size: 1rem;
                            font-family: $font-secondary;
                            font-weight: $weight-bold;
                            line-height: 30px;
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }

                    .user-profile-links {
                        padding: 15px 0;
                        border-bottom: 1px solid $header-submenu-border;

                        a {
                            display: block;
                        }
                    }

                    .user-profile-logout {
                        a {
                            i {
                                font-size: 1.5rem;
                                vertical-align: middle;
                                margin-right: 10px;
                            }
                        }

                    }
                }
            }
        }
    }

    .institutional-banner-desktop {
        background: $header-institutional-banner-background;

        @media only screen and (max-width:1024px) {
            display: none;
        }

        .institutional {
            min-height: 40px;
            padding: 10px 0 !important;

            .row {
                margin: 0 37px;

                @media only screen and (max-width:1199px) {
                    margin-left: 13px;
                }

                .heading {
                    color: $header-institutional-banner-heading;
                    font-weight: $weight-regular;
                }

                ul {
                    @include flexbox;
                    @include align-items(stretch);
                    @include flex-wrap(nowrap);


                    li {
                        font-size: 0.95rem;
                        font-weight: $weight-medium;
                        height: 20px;
                        line-height: 20px;

                        background: $header-institutional-banner-link-background;
                        &:hover, &:focus  {
                            background: $header-institutional-banner-link-background-hover;
                        }
                        &:active {
                            background: $header-institutional-banner-link-background-active;
                        }
                        &:disabled {
                            background: $header-institutional-banner-link-background-disabled;
                        }

                        a {
                            display: block;
                            color: $header-institutional-banner-link;

                            &:hover, &:focus {
                                color: $header-institutional-banner-link-hover;
                            }
                            &:active {
                                color: $header-institutional-banner-link-active;
                            }
                            &:disabled {
                                color: $header-institutional-banner-link-disabled;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "./responsive";
